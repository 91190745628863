import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Alerts from '../../Alerts'
import Commonheader from '../../Common/Commonheader'
import Footer from '../../Common/Footer'
import Config from '../../Config';
import Select from "react-select";

function Temporaryhoteledit() {
    const location = useLocation();
    const params=useParams();

    const [ids, setId] = useState(params.hotelid);
const [h_type,setH_type]=useState(params.type);
    let navigate = useNavigate();

    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [open, setOpen] = useState(false);
    const [hotelname, setHotelname] = useState("");
    const [email, setEmail] = useState("");
    const [groupname, setGroupname] = useState("");
    const [type,setType]=useState("");
    const [property,setProperty]=useState("");
    const [formerror, setError] = useState({});
    const [formerror1, setError1] = useState({});
    const [formerror2, setError2] = useState({});
    const [formerror3, setError3] = useState([{}]);
    const [formerror4, setError4] = useState({});
    const [formerror5, setError5] = useState({});
    const [formerror7, setError7] = useState({});

const [number,setNumber]=useState(0);
const [count,setCount]=useState(false);

    const yesorno = [
      { value: "individual", label: "Individual"},
        { value: 'group', label: "Group" }, 
      ];

const[list,setList]=useState([]);
const [deleteinput,setDeleteinput]=useState([]);
const [inputFields, setInputFields] = useState([
    {
      // grop_name:"",reg_email:"",
      // property_type:""
     
    }
  ]);

  const addInputFields = (ind) => {
    setInputFields([
      ...inputFields,
      {
        grop_name:"",
        reg_email:"",
        property_type:"",
        hotel_id:""
    },
    ]);
     setNumber(number+1)

  };
  const addInputField = (count) => {
    const newInputFields = [...inputFields];
    const num=inputFields.length;
    for (let i = 0; i < count; i++) {
      newInputFields.push({
        grop_name: "",
        reg_email: "",
        property_type: ""
      });
    }
    
    setInputFields([ ...newInputFields]);
  };
//   const addInputFields = (e) => {
//         setInputFields([
//           ...inputFields,
//           {
//             grop_name: "",
//             reg_email: "",
//             property_type: ""
//           },
//         ]);
//         // setNumber(number+1)
//       };
   
  
  const removeInputFields = (index) => {
    const rows = [...inputFields];

   const deletedata= rows.splice(index, 1);
setDeleteinput([...deleteinput,deletedata])

    setInputFields(rows);
    setNumber(number-1)
    

  };


  let handleChange = (i, e) => {
    let newval = [...inputFields];
    inputFields[i][e.target.name] = e.target.value;
    setInputFields(newval);
  }
  


  function Reset(){
setHotelname("");
setEmail("");
setGroupname("");
setType("");
setProperty("");
setInputFields([
    ...inputFields,
    {
      grop_name:"",
  },
  ]);
  }

  const getproperty = async (e) => {

    const res = await fetch(`${Config.apiurl}property-types`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",

      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data);
    }
  }
   

  const getinfo = async (e) => {
 
    if(h_type==="individual"){
        const res = await fetch(`${Config.apiurl}temporary-hotel/edit/${ids}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const data = await res.json();
          setType({
             value: "individual", label: "Individual"
             
          });
       setHotelname(data.data.hotel.hotel_name);
          setEmail(data.data.hotel.registration_email);
          setProperty(data.data.property_type);
    }
    else{
    const res = await fetch(`${Config.apiurl}temporary-hotel/group/edit/${ids}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    setType( 
    { value: 'group', label: "Group" })
    setGroupname(data.data.group.name);
    setNumber(data.data.group.no_of_hotels);

    const newInputFields = [];
    
    for (let i = 0; i < data.data.hotels.length; i++) {
      newInputFields.push({
        grop_name: data.data.hotels[i].hotel_name,
        reg_email: data.data.hotels[i].registration_email,
        property_type: data.data.hotels[i].frequency,
        hotel_id:data.data.hotels[i].hotel_id
      });
    }  
    setInputFields([ ...newInputFields]);
   
}
    
  };



  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values) {
      errors.email = "Email is required!";
    }
    else if (!regex.test(values)) {
      errors.email = "This is not a valid email format!";
    }
    
    return errors;
  };
  const validatehotel = (values) => {
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_]+$/;
    const errors = {};
    if (!values) {
      errors.hotel = "Hotel name is required!";
    }   
    else if (!noSpecialCharRegex.test(values)) {
      errors.hotel = "Hotel name should not contain this special character";
    }
    else if (values.length > 100) {
      errors.hotel = `Hotel name cannot be more than 100 characters!`;
    }
    return errors;

  };
  const validatetype = (values) => {

    const errors = {};
    if (!values) {
      errors.type = "Type is required!";
    }
   
    
    return errors;
  };
  const validatepropertytype = (values) => {
    const errors = {};
    if (!values) {
      errors.property = "Property type is required!";
    }
   
    
    return errors;
  };
  const validategroup = (values) => {
    const errors = {};
    if (!values) {
      errors.group = "Group name is required!";
    }
   
    
    return errors;

  };
  const validateinput = (values) => {
    setCount(false);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
let arr=[];
  for(let i=0;i<values.length;i++){
    const errors = {};
    if (!values[i].grop_name) {
      errors.grop_name = "Hotel name is required!";
    }
     if (!values[i].reg_email) {
      errors.reg_email = "Email is required!";
    }
   
    else if (!regex.test(values[i].reg_email)) {
      errors.reg_email= "This is not a valid email format!";
    }

     if (!values[i].property_type) {
      errors.property_type = "Property type is required!";
    }
    //  return errors;
    arr.push(errors);
    }
    setError3(arr)

  }

    const validatenum = (values) => {
    
      const errors = {};
      if (!values) {
        errors.num = "Number of hotel is required!";
      }
      // else if(values!==count) {
      //   errors.num = `Please fill ${values} hotels  details!`;
      // }
     
      return errors;
      };

    
  const PostData = async (e) => {
    e.preventDefault();
   
    if(type.value==="group"){

    //     let group_hotel = [];
     
    // let num=0;
    //     for (let i = 0; i < inputFields.length; i++) {
    //       if (inputFields[i].grop_name && inputFields[i].reg_email && inputFields[i].property_type) {
    //         let ob={};
    //            ob.name=inputFields[i].grop_name;
    //            ob.hotel_id=inputFields[i].hotel_id;
    //            ob.email=inputFields[i].reg_email;
    //            ob.property_type=inputFields[i].property_type.value;
    //            group_hotel.push(ob);

    //         num=num+1;
    //       }
    //     }
        const resstype=validatetype(type);
        const ressgroup=validategroup(groupname);
        const ressinput=validateinput(inputFields);
        const ressnum=validatenum(number);
        setError2(ressgroup);
        //  setError3(ressinput);
         setError7(ressnum);

         setError1(resstype);

         for(let i=0;i<formerror3.length;i++){
          if(formerror3[i]===undefined||formerror3[i]===null||formerror3[i]===""|| !formerror3[i].grop_name|| !formerror3[i].reg_email ||!formerror3[i].property_type){
            console.log("2");
          }
          else{     
            setCount(true);
                   }
                  }


        if (
          
            !resstype.type &&
            !ressgroup.group &&
            !ressnum.num && count===false
           
          ) {
        
            const formData = new FormData();
            formData.append("hotel_type", type.value);
            for (let i = 0; i < inputFields.length; i++) {
                      if (inputFields[i].grop_name && inputFields[i].reg_email && inputFields[i].property_type) {
                        if(!inputFields[i].hotel_id || inputFields[i].hotel_id===undefined || inputFields[i].hotel_id===""||inputFields[i].hotel_id===null){
                            formData.append(`hotels[${i}][hotel_id]`,"");
                        }
                        else{
                            formData.append(`hotels[${i}][hotel_id]`,inputFields[i].hotel_id);
                        }

                        formData.append(`hotels[${i}][name]`,inputFields[i].grop_name);
                        formData.append(`hotels[${i}][email]`,inputFields[i].reg_email);
                        formData.append(`hotels[${i}][property_type]`,inputFields[i].property_type.value);
                      }
                    }
            
            formData.append("group_name", groupname);
            formData.append("no_of_hotels", number);
            let num=0;
            for(let i=0;i<deleteinput.length;i++){
                if(deleteinput[i][0].hotel_id || !deleteinput[i][0].hotel_id===undefined || !deleteinput[i][0].hotel_id===""|| !deleteinput[i][0].hotel_id===null){
                    formData.append(`remove_hotels[${num}]`, deleteinput[i][0].hotel_id);
                        num=num+1;
                }

            }

            axios.post(`${Config.apiurl}temporary-hotel/update/group/${ids}`,formData, {
         
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
                .then((res) => {
              if (res.status_code !== "200" || !res) {
          
                      setAlert(true);
                      setAlertmessage(res.message);
                      setOpen(true);
                      setAlerttype("error");
                    } else {
                      setAlert(true);
                      setAlertmessage(res.message);
                      setOpen(true);
                      setAlerttype("success");
                      Reset();
                      setTimeout(() => {
                        navigate("/temporary-property", { state: { content: "group" } });
                      }, 3000);    
                
                    }
                })
        }
        else{
        console.log("erroes");
        }
    }

    else if(type.value==="individual"){
        const resshotel=validatehotel(hotelname);
        const resstype=validatetype(type);
        const ressproperty = validatepropertytype(property);
        const ress = validate(email);
        setError4(ress);
        setError5(ressproperty);
        setError(resshotel);
        setError1(resstype);
        // setError1(resshotel);
        // setError(ress);
        // setError5(ressproperty);

        // setError2(resstype);
        if (
            !ress.email &&
            !resshotel.hotel &&
            !resstype.type &&
            !ressproperty.property

          ) {
            const formData = new FormData();
      formData.append("hotel_type", type.value);
      formData.append("group_hotel_name", "");
      formData.append("no_of_hotels", 0);
      formData.append("hotel_name", hotelname);
      formData.append("group_hotel_names", []);
      formData.append("registration_email", email);
      formData.append("property_type", property.value);

      axios.post(`${Config.apiurl}temporary-hotel/update/${ids}`, formData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
                .then((res) => {
               
    
              if (res.status_code !== "200" || !res) {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("error");
              } else {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("success");
                Reset();
                setTimeout(() => {
                  navigate("/temporary-property", { state: { content: "individual" } });
                }, 3000);    
          

              }
   
          })
    }

      }
    else{

        const resshotel=validatehotel(hotelname);
        const resstype=validatetype(type);
        const ressproperty = validatepropertytype(property);
        const ress = validate(email);
       const ressnum=validatenum(number);
        setError4(ress);
        setError5(ressproperty);
        setError(resshotel);
        setError1(resstype);
setError7(ressnum);
    }
   
  };


  useEffect(() => {
    getproperty();
    getinfo();
  },[]
  )
  function handleChangeselect(selectedOption,index,nameval) {
    let name = nameval;
    let value = selectedOption;
    let newval = [...inputFields];
    inputFields[index][name] = value;
    setInputFields(newval);
   }
  return (
    <div id="layout-wrapper">
    <Commonheader/>
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title">Hotel Creation</h4>
               
              </div> */}
            </div>
          </div>
          {alert ? (
            <Alerts type={alerttype} message={alertmessage} open={open} />
          ) : (
            ""
          )}
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Update Hotel
                    </h4>
                    <div className="flex-shrink-0">
                      <Link
                        // type="button"
                        className="btn db-other-button "
                        to="/temporary-property"
                        style={{ marginRight: "5px" }}
                      >
                      Hotels list
                      </Link>
                      {/* <button
                        type="button"
                        className="btn db-cancel-button"
                        data-bs-toggle="offcanvas"
                        to=""
                         onClick={Reset}
                      >
                        Cancel
                      </button> */}
                      <button
                        type="button"
                        className="btn db-save-button"
                        data-bs-toggle="modal"
                        id="create-btn"
                        data-bs-target=""
                         onClick={PostData}
                         style={{height:"37px"}}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">

                        <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Hotel Type
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                 
                                  <Select
                                  className="form-control taxselect"
                                  id="exampleInputUsername1"
                                  options={yesorno}
                                 value={type}
                    onChange={(val)=>setType(val)}
                    />
                                  
                                 {/* <select
                                 id="ForminputState"
                                 className="form-select"
                                 value={type}
                                    onChange={(e) =>
                                      setType(e.target.value)
                                    }
                                 >
                                 <option selected>Choose...</option>
                                    <option value="individual">Individual</option>
                                    <option value="group">Group</option>
                                 </select> */}
                                  <label style={{ color: "red" }}>
                                    {formerror1.type}
                                  </label>
                                </div>
                              </div>
                           {type.value==="group" ?
                           <>
                            <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                   Group Hotel Name
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={groupname}
                                    onChange={(e) =>
                                      setGroupname(e.target.value)
                                    }
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror2.group}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                   Number of Hotels
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    value={number}
                                    disabled
                                    // onChange={(e) =>{
                                    //   setNumber(e.target.value); addInputField(e.target.value)
                                    // }
                                    // }
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror7.num}
                                  </label>
                                </div>
                              </div>


                              {number?
                              <>
                               <div className="row">
                                    <div className="col-md-4">
                                    <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Hotel Names
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                    </div>
                                    <div className="col-md-4  ">
                               
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                  Registration Email
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  </div>
                                  <div className="col-md-4">
                               
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                 Property Type
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  </div>
                                </div>
                                  {inputFields.map((data, index) => {
                              return (

                                <div key={index}>
                                   <div className="row">
                                    <div className="col-md-4">
                                    <div className="mb-3">
                                    {/* <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Hotel Names
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label> */}
                           
                             
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  onChange={(e) => handleChange(index, e)}
                                  value={data.grop_name}
                                  name="grop_name"
                                />
                               
                              { formerror3.map((err,i)=>{
                                return(
                                    <>
                                          {index===i && err && err.grop_name?
                                              <label style={{ color: "red" }}>
                                                    {err.grop_name}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })}
                                {/* { formerror3 && index===0 && formerror3.grop_name?
                                <label style={{ color: "red" }}>
                                                    {formerror3.grop_name}
                                                  </label>
                                                  
                                                  :""} */}
                             
                           
                            </div>
                            </div>
                           
                            <div className="col-md-3  ">
                                <div className="mb-3">
                                  {/* <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                  Registration Email
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label> */}
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    onChange={(e) => handleChange(index, e)}
                                  value={data.reg_email}
                                  name="reg_email"
                                  />
                                  { formerror3.map((err,i)=>{
                                return(
                                    <>
                                          {index===i && err && err.reg_email?
                                              <label style={{ color: "red" }}>
                                                    {err.reg_email}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })}
                                       {/* { formerror3 && index===0 && formerror3.reg_email?
                                  <label style={{ color: "red" }}>
                                    {formerror3.reg_email}
                                  </label>
                                  :""} */}
                                </div>
                              </div>
                          
                              <div className="col-md-3">
                                <div className="mb-3">
                                  {/* <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                 Property Type
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label> */}
                                  <Select
                                  className="form-control taxselect"
                                  id="exampleInputUsername1"
                                  options={list}
                                 value={data.property_type}
                    onChange={(val)=>handleChangeselect(val,index,"property_type")}
                    />
                                  {/* <select
                                 id="ForminputState"
                                 className="form-select"
                                 onChange={(e) => handleChange(index, e)}
                                 value={data.property_type}
                                 name="property_type"
                                 >
                                 <option selected>Choose...</option>
                                 {list.map((pro,i)=>{
                                    return(
                                        <option value={pro.property_id} key={i}>{pro.en_name}</option>

                                    )
                                 })}
                                   
                                 </select> */}

                            { formerror3.map((err,i)=>{
                                return(
                                    <>
                                          {index===i && err && err.property_type?
                                              <label style={{ color: "red" }}>
                                                    {err.property_type}
                                                  </label>
                                                  :""}
                                    </>
                                )
                               })}
                              
                                </div>
                              </div>
                              <div className="col-md-2">
                                    <span className={index===inputFields.length-1?"extrarate-room-link":"extrarate-room-link d-none"}>
                                        <button type="button" className="extra-rate-add button_default_add" onClick={(e)=>addInputFields(index)}>+</button>
                                      </span>
                                      <button type="button" className={inputFields.length-1===0?"d-none":"remove_more button_default"} onClick={(e)=>removeInputFields(index)}>X</button>
                                </div>
                                   
                             
            </div>
     
            <div className="clearfix"></div>
                            </div>      

                              )})}
                              </>
                              :""}
            <div className="hk_frequency_appendmore"></div>
            <div className="padding-10"></div>
                           </>
                           :
                           <>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                   Hotel Name
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={hotelname}
                                    onChange={(e) =>
                                      setHotelname(e.target.value)
                                    }
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror.hotel}
                                  </label>
                                </div>
                              </div>
                              
                               <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                  Registration Email
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={email}
                                    onChange={(e) =>
                                      setEmail(e.target.value)
                                    }
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror4.email}
                                  </label>
                                </div>
                              </div>
                            
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                 Property Type
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <Select
                                  className="form-control"
                                  id="exampleInputUsername1"
                                  options={list}
                                 value={property}
                    onChange={(val)=>setProperty(val)}
                    />
                                  {/* <select
                                 id="ForminputState"
                                 className="form-select"
                                 value={property}
                                    onChange={(e) =>
                                      setProperty(e.target.value)
                                    }
                                 >
                                 <option selected>Choose...</option>
                                 {list.map((pro,i)=>{
                                    return(
                                        <option value={pro.property_id} key={i}>{pro.en_name}</option>

                                    )
                                 })}
                                   
                                 </select> */}
                                  <label style={{ color: "red" }}>
                                    {formerror5.property}
                                  </label>
                                </div>
                              </div>
</>}
                           
                            
                    
                        </div>
                        <div className="padding-20"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
  )
}

export default Temporaryhoteledit
