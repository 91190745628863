import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Alerts from "../../Alerts";
import Roomheader from "../../Common/Roomheader";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Footer from "../../Common/Footer";
import Toastmodal from "../../Common/Toastmodal";
import Loading from "../../Loading";

function Roomdescriptions() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [language, setLanguage] = useState([]);
  const [lan, setLan] = useState("en");
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine/languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setLanguage(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getValue();
    } else {
      console.log("error");
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room-description/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          language_code: lan,
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    console.log(lan);
    const response = await fetch(
      `${Config.apiurl}hotel/room-description/list?page=1`,
      {
        method: "POST",
        body: JSON.stringify({
          language_code: lan,
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const data = await response.json();

      if (data.status_code === "200") {
        setList(data.data.data);
        setPaginate(data.data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getValue();
      }
    } else {
      console.log("error");
    }
  };

  const languagesubmit = async (val) => {
    setList([]);
    const res = await fetch(`${Config.apiurl}hotel/room-description/search`, {
      method: "POST",
      body: JSON.stringify({
        language_code: val,
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();

      if (data.status_code === "200") {
        setList(data.data.data);
        setPaginate(data.data.last_page);
      } else if (data.reauth === true) {
        handleUnauthorized();
        languagesubmit(val);
      }
    } else {
      console.log("error");
    }
  };

  // let handleChangees = (index, i, e) => {
  //   let newval = [...list];
  //   list[index]["rooms"][i][e.target.name] = e.target.value;
  //   setList(newval);
  // };

  const handleChangees = (index, i, e) => {
    const updatedList = [...list];
    updatedList[index].rooms[i][e.target.name] = e.target.value;
    setList(updatedList);

    const changedDataCopy = [...changeddata];
    // changedDataCopy[i] = {
    //   ...changedDataCopy[i],
    //   hotel_id: newval[i].hotel_id,
    //   rooms: newval[i]["rooms"].map((room, roomIndex) => {
    //     if (roomIndex === index) {
    //       return {
    //         ...room,
    //         room_id: room.room_id,
    //         [name]: value,
    //       };
    //     } else {
    //       return room;
    //     }
    //   }),
    // };
    changedDataCopy[index] = {
      ...changedDataCopy[index],
      hotel_id: list[index].hotel_id,
      rooms: list[index].rooms.map((room, roomIndex) => {
        if (roomIndex === i) {
          return {
            ...room,
            room_id: room.room_id,
            [e.target.name]: e.target.value,
          };
        }
        return room;
      }),
    };
    setChangeddata(changedDataCopy);
  };

  useEffect(() => {
    fetchPost();
    getValue();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  const Update = async (e) => {
    e.preventDefault();
    if (changeddata?.length > 0) {
      let arr = [];
      for (let i = 0; i < changeddata?.length; i++) {
        if (changeddata[i] !== undefined) {
          if (changeddata[i].hotel_id) {
            let hotel = {
              hotel_id: changeddata[i].hotel_id,
              rooms: [],
            };
            for (let j = 0; j < changeddata[i]?.rooms?.length; j++) {
              let room = {
                room_id: changeddata[i].rooms[j].room_id,
                description: changeddata[i].rooms[j].description,
                short_description: changeddata[i].rooms[j].short_description,
                marketing_title: changeddata[i].rooms[j].marketing_title,
              };
              hotel.rooms.push(room);
            }

            arr.push(hotel);
          }
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/room-description/bulk-update`,
        {
          method: "POST",
          body: JSON.stringify({
            language_code: lan,
            hotels: arr,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await res.json();
      if (data.status_code === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        languagesubmit(lan);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Update();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    } else {
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };
  // let Update = async () => {
  //   let formdata = new FormData();
  //   formdata.append("language_code", lan);
  //   for (let i = 0; i < list.length; i++) {
  //     for (let j = 0; j < list[i].rooms.length; j++) {
  //       formdata.append(`rooms[${j}][room_id]`, list[i].rooms[j].room_id);
  //       if (list[i].rooms[j].description) {
  //         formdata.append(
  //           `rooms[${j}][description]`,
  //           list[i].rooms[j].description
  //         );
  //       } else {
  //         formdata.append(`rooms[${j}][description]`, "");
  //       }
  //       if (list[i].rooms[j].short_description) {
  //         formdata.append(
  //           `rooms[${j}][short_description]`,
  //           list[i].rooms[j].short_description
  //         );
  //       } else {
  //         formdata.append(`rooms[${j}][short_description]`, "");
  //       }
  //       if (list[i].rooms[j].marketing_title) {
  //         formdata.append(
  //           `rooms[${j}][marketing_title]`,
  //           list[i].rooms[j].marketing_title
  //         );
  //       } else {
  //         formdata.append(`rooms[${j}][marketing_title]`, "");
  //       }
  //     }
  //   }
  //   axios
  //     .post(`${Config.apiurl}hotel/room/description/${id}`, formdata, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status_code === "200") {
  //         setToastmodal(true);
  //         setToastmessage(res.message);
  //         setToasttype("success");
  //         setToastlarge(false);
  //         languagesubmit(lan);
  //       } else if (res.reauth === true) {
  //         handleUnauthorized();
  //         Update();
  //       } else {
  //         setToastmodal(true);
  //         setToastmessage(res.message);
  //         setToasttype("error");
  //         setToastlarge(false);
  //       }
  //     });
  // };

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  return (
    <div>
      <Roomheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm">
                          <h5 className="card-title mb-0">
                            List Of Hotel Group
                          </h5>
                        </div>
                        <div className="col-sm">
                          <div className="col-xxl-8 col-sm-6 lang-selector-list lang-rearrange">
                            <div className="d-flex">
                              <div
                                className="col-lg-6"
                              >
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Choose Language:
                                  </label>
                                  <select
                                    className="form-control"
                                    data-choices
                                    data-choices-search-false
                                    id="idPayment"
                                    name="lan"
                                    value={lan}
                                    onChange={(e) => {
                                      setLan(e.target.value);
                                      languagesubmit(e.target.value);
                                    }}
                                  >
                                    <option>Language</option>
                                    {language.map((lan, i) => {
                                      return (
                                        <option
                                          name="lan"
                                          value={lan.language_code}
                                          key={i}
                                        >
                                          {lan.language_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                                <Link
                                  type="button"
                                  to="#"
                                  className="btn db-save-button "
                                  onClick={Update}
                                >
                                  Update
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        {/* <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul> */}
                        {loader===false?
                        <Loading/>
                        :
                        <div className="">
                          {list.map((li, indx) => {
                            return (
                              <div key={indx}>
                                {list.length > 0 ? (
                                  <div
                                    className={
                                      list.length > 1
                                        ? "row hotel_single_list"
                                        : "row"
                                    }
                                  >
                                    {list.length > 1 ? (
                                      <div className="hotel_service_display_12">
                                        <div className="hk_service_all_hotel">
                                          <span className="">
                                            {" "}
                                            {pageindex === 1
                                              ? indx + 1
                                              : (pageindex - 1) * 10 + indx + 1}
                                            .
                                          </span>
                                          <span style={{ marginLeft: "10px" }}>
                                            {li.hotel_name}
                                          </span>
                                        </div>
                                        {showFullData[indx]?.hotel === true ? (
                                          <span
                                            className="db-modify_toggle_btn"
                                            onClick={() =>
                                              handleDropdownClickhotel(indx)
                                            }
                                          >
                                            <span
                                              className="bx bxs-chevron-up"
                                              style={{ fontSize: "20px" }}
                                            ></span>
                                          </span>
                                        ) : (
                                          <span
                                            className="db-modify_toggle_btn"
                                            onClick={() =>
                                              handleDropdownClickhotel(indx)
                                            }
                                          >
                                            <span
                                              className="bx bxs-chevron-down"
                                              style={{ fontSize: "20px" }}
                                            ></span>
                                          </span>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <table
                                      id="fixed-header"
                                      className={`table table-nowrap align-middle ${
                                        showFullData[indx]?.hotel === true
                                          ? ""
                                          : "d-none"
                                      }`}
                                    >
                                      <thead className="text-muted table-light">
                                        <tr className="text-uppercase">
                                          <th
                                            className=""
                                            data-sort="customer_name"
                                            width="25%"
                                          >
                                            Room Title
                                          </th>
                                          <th
                                            className=""
                                            data-sort="status"
                                            width="75%"
                                          >
                                            Description
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                        {li.rooms.map((des, index) => {
                                          return (
                                            <tr key={index}>
                                              <td className="">
                                                <div className="">
                                                  {des.room_title}
                                                </div>
                                                <div className="room-desc-title-sec">
                                                  <label
                                                    for="firstNameinput"
                                                    className="form-label room-desc-label"
                                                  >
                                                    Marketing Caption
                                                  </label>
                                                  <textarea
                                                    className="form-control marketing_title_field"
                                                    row="1"
                                                    value={des.marketing_title}
                                                    name="marketing_title"
                                                    onChange={(e) =>
                                                      handleChangees(
                                                        indx,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  ></textarea>
                                                </div>
                                              </td>
                                              <td colspan="3" className="">
                                                <div className="">
                                                  <label
                                                    for="firstNameinput"
                                                    className="form-label room-desc-label"
                                                  >
                                                    Description
                                                  </label>
                                                  <textarea
                                                    className="form-control"
                                                    rows="2"
                                                    value={des.description}
                                                    onChange={(e) =>
                                                      handleChangees(
                                                        indx,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                    name="description"
                                                  ></textarea>
                                                </div>
                                                <div className="room-desc-title-sec">
                                                  <label
                                                    for="firstNameinput"
                                                    className="form-label room-desc-label"
                                                  >
                                                    Short Description
                                                  </label>
                                                  <textarea
                                                    className="form-control"
                                                    rows="1"
                                                    value={
                                                      des.short_description
                                                    }
                                                    name="short_description"
                                                    onChange={(e) =>
                                                      handleChangees(
                                                        indx,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  ></textarea>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </div>
  );
}

export default Roomdescriptions;
